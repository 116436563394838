var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',[_c('DynamicUTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"multiTable",attrs:{"columns":_vm.tableProperties,"need-check-box":false,"need-pagination":false,"need-search":true,"tableData":_vm.tableData},on:{"getTableData":_vm.getTableData},scopedSlots:_vm._u([{key:"requ_infompush_reason",fn:function(scope){return [(scope.row.requ_infompush_reason)?_c('el-input',{attrs:{"value":scope.row.requ_infompush_reason,"disabled":"","show-word-limit":"","size":"small"}},[_c('template',{slot:"append"},[_c('el-popover',{attrs:{"append-to-body":false,"placement":"top","popper-class":"full-input","trigger":"click","width":"600"}},[_c('div',{staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(scope.row.requ_infompush_reason)}}),_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_c('el-link',{staticClass:"vg_cursor",attrs:{"type":"info"}},[_c('i',{staticClass:"el-icon-more"})])],1)])],1)],2):_vm._e()]}},{key:"operation",fn:function(scope){return [_c('el-link',{staticClass:"vg_mr_8 vg_cursor",attrs:{"size":"mini","type":"primary"},on:{"click":function($event){_vm.jump('/fequ_edit', {
            key: _vm.$UrlEncode.encode(
              JSON.stringify({
                perm_id: 116,
                form_id: scope.row.form_id
              })
            )
          })}}},[_vm._v("查看 ")]),(scope.row.requestpush_type === 0)?_c('el-link',{staticClass:"vg_cursor vg_mr_8",attrs:{"size":"mini","type":"success"},on:{"click":function($event){return _vm.refusalToReturnSamples(scope.row)}}},[_vm._v("接收 ")]):_vm._e(),(scope.row.requestpush_type === 0)?_c('el-link',{staticClass:"vg_cursor vg_mr_8",attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.refusalToReturnSamples(scope.row, 1)}}},[_vm._v("驳回 ")]):_vm._e(),(scope.row.requestpush_type === 1)?_c('el-link',{staticClass:"vg_cursor vg_mr_8",attrs:{"size":"mini","type":"success"},on:{"click":function($event){return _vm.receive(scope.row)}}},[_vm._v("接收")]):_vm._e(),(scope.row.requestpush_type === 1)?_c('el-link',{staticClass:"vg_cursor vg_mr_8",attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.reject(scope.row)}}},[_vm._v("驳回")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }