<template>
  <el-card>
    <DynamicUTable
      ref="multiTable"
      v-loading="loading"
      :columns="tableProperties"
      :need-check-box="false"
      :need-pagination="false"
      :need-search="true"
      :tableData="tableData"
      @getTableData="getTableData"
    >
      <template v-slot:requ_infompush_reason="scope">
        <el-input v-if="scope.row.requ_infompush_reason" :value="scope.row.requ_infompush_reason" disabled show-word-limit size="small">
          <template slot="append">
            <el-popover :append-to-body="false" placement="top" popper-class="full-input" trigger="click" width="600">
              <div style="white-space: pre-line" v-html="scope.row.requ_infompush_reason"></div>
              <span slot="reference">
                <el-link class="vg_cursor" type="info"><i class="el-icon-more" /> </el-link>
              </span>
            </el-popover>
          </template>
        </el-input>
      </template>
      <template v-slot:operation="scope">
        <el-link
          class="vg_mr_8 vg_cursor"
          size="mini"
          type="primary"
          @click="
            jump('/fequ_edit', {
              key: $UrlEncode.encode(
                JSON.stringify({
                  perm_id: 116,
                  form_id: scope.row.form_id
                })
              )
            })
          "
          >查看
        </el-link>
        <!--<el-link v-if="scope.row.requestpush_type === 0" class="vg_cursor vg_mr_8" size="mini" type="danger" @click="refusalToReturnSamples(scope.row)"-->
        <!--  >删除-->
        <!--</el-link>-->
        <el-link v-if="scope.row.requestpush_type === 0" class="vg_cursor vg_mr_8" size="mini" type="success" @click="refusalToReturnSamples(scope.row)"
          >接收
        </el-link>
        <el-link v-if="scope.row.requestpush_type === 0" class="vg_cursor vg_mr_8" size="mini" type="danger" @click="refusalToReturnSamples(scope.row, 1)"
          >驳回
        </el-link>
        <el-link v-if="scope.row.requestpush_type === 1" class="vg_cursor vg_mr_8" size="mini" type="success" @click="receive(scope.row)">接收</el-link>
        <el-link v-if="scope.row.requestpush_type === 1" class="vg_cursor vg_mr_8" size="mini" type="danger" @click="reject(scope.row)">驳回</el-link>
      </template>
    </DynamicUTable>
  </el-card>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { requAPI } from '@api/modules/requ';
import { cloneDeep } from 'lodash';
import $UrlEncode from '@assets/js/UrlEncode';
import UrlEncode from '@assets/js/UrlEncode';
import { getDateNoTime } from '@assets/js/dateUtils';

export default {
  name: 'push9007',
  computed: {
    $UrlEncode() {
      return $UrlEncode;
    }
  },
  components: { DynamicUTable },
  data() {
    return {
      tableData: [],
      loading: true,
      tableProperties: [
        {
          label: '更新类别',
          prop: 'requestpush_type',
          itemType: 'select',
          options: [
            { value: 0, label: '附件更新' },
            { value: 1, label: '描述更新' }
          ],
          input: true,
          sortable: false,
          widthAuto: true,
          formatter: val => (val === 0 ? '附件更新' : '描述更新')
        },
        { label: '委托单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '工厂打样单号', prop: 'fequ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '更改号', prop: 'prod_suffix', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '描述', prop: 'requ_infompush_reason', itemType: 'input', input: false, sortable: false, widthAuto: true },
        {
          label: '更新时间',
          prop: 'create_time',
          itemType: 'date',
          input: false,
          sortable: false,
          widthAuto: true,
          formatter: val => getDateNoTime(val, false)
        },
        { label: '操作', prop: 'operation', itemType: 'input', input: false, sortable: false, widthAuto: true }
      ]
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      searchForm.requ_taker = this.$cookies.get('userInfo').stff_id;
      requAPI.requInfomPush(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.loading = false;
      });
    },
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    refusalToReturnSamples(row, type) {
      row.type = type;
      requAPI.editFequSample9007D(row).then(({ data }) => {
        this.$message.success('操作成功!');
        this.getTableData();
      });
    },
    receive(row) {
      requAPI.editFequSample9007Y(row).then(({ data }) => {
        this.$message.success('操作成功!');
        this.getTableData();
        this.jump('/fequ_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 116,
              form_id: row.form_id
            })
          )
        });
      });
    },
    reject(row) {
      requAPI.editFequSample9007N(row).then(({ data }) => {
        this.$message.success('操作成功!');
        this.getTableData();
      });
    }
  }
};
</script>

<style></style>
